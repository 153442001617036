<script lang="ts" setup>
const auth = useAuth()
useHead({
  title: 'AMM²',
})

const store = useAMM()
</script>

<template>
  <div>
    <NuxtLayout name="dashboard">
      <template #header>
        <div class="flex space-x-4 items-center">
          <NuxtLink to="/automated-market-making/" class="flex items-center">
            <UIcon name="i-heroicons-arrow-left" class="w-5 h-5" />
          </NuxtLink>
          <div>Create a new strategy</div>
        </div>
        <div>
          <UiSteps :steps="store.steps" :index="store.step" />
        </div>
      </template>
      <div>
        <div>
          <slot />
        </div>
      </div>
    </NuxtLayout>
  </div>
</template>
